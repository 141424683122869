@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

body {
  top: 0;
  bottom: 6rem;
  left: 0;
  right: 0;
  padding: 1.5rem;
}

p, .header, .label {
  font-family: 'Muli' !important;
}

p {
  font-size: 1.1rem;
}

h2.header {
  font-weight: 600 !important;
}