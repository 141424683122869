@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
.Subject {
  font-size: 1.6rem !important;
  line-height: 2.2rem !important;
  padding-bottom: 0.2rem !important;
}

div.Label {
  margin-left: 0 !important;
  margin-right: 0.4rem !important;
  margin-bottom: 0.4rem !important;
}

/* @media only screen and (max-width: 40rem) {
  div.Label.Lecturer {
    display: none;
  }
} */
.Menu {
  margin: 0 auto;
  position: fixed;
  bottom: -3px;
  left: 0;
  right: 0;
  width: 100vw;
  max-width: 30rem;
  z-index: 900;
}
section {
  margin-top: 1rem;
}

section > .ui.medium.header {
  margin-bottom: 0.5rem !important;
}

.ExistingFilterAction {
  cursor: pointer !important;
  border-radius: 0.3rem !important;
}
.Page {
  width: 100%;
  max-width: 50rem;
  margin-bottom: 5rem;
  padding-bottom: 80px;
}

.Loader {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 960;
}
body {
  top: 0;
  bottom: 6rem;
  left: 0;
  right: 0;
  padding: 1.5rem;
}

p, .header, .label {
  font-family: 'Muli' !important;
}

p {
  font-size: 1.1rem;
}

h2.header {
  font-weight: 600 !important;
}
html {
  max-width: 50em;
  margin: 0 auto;
  left: 0;
  right: 0;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Consolas, 'Courier New', monospace;
}

