.Subject {
  font-size: 1.6rem !important;
  line-height: 2.2rem !important;
  padding-bottom: 0.2rem !important;
}

div.Label {
  margin-left: 0 !important;
  margin-right: 0.4rem !important;
  margin-bottom: 0.4rem !important;
}

/* @media only screen and (max-width: 40rem) {
  div.Label.Lecturer {
    display: none;
  }
} */