section {
  margin-top: 1rem;
}

section > .ui.medium.header {
  margin-bottom: 0.5rem !important;
}

.ExistingFilterAction {
  cursor: pointer !important;
  border-radius: 0.3rem !important;
}