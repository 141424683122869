html {
  max-width: 50em;
  margin: 0 auto;
  left: 0;
  right: 0;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Consolas, 'Courier New', monospace;
}
